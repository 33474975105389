/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

// typography
$brandmagic-typography: mat.define-typography-config(
	$font-family: 'Albert Sans, Helvetica, Arial, sans-serif',
	$display-4: mat.define-typography-level(
		$font-size: 4rem,
		$font-weight: 200,
		$line-height: 1.3,
	),
	$display-3: mat.define-typography-level(
		$font-size: 3.7rem,
		$font-weight: 300,
		$line-height: 1.3,
	),
	$display-2: mat.define-typography-level(
		$font-size: 3.3rem,
		$font-weight: 400,
		$line-height: 1.3,
	),
	$display-1: mat.define-typography-level(
		$font-size: 3rem,
		$font-weight: 600,
		$line-height: 1.3,
	),
	$headline: mat.define-typography-level(
		$font-size: 1.8rem,
		$font-weight: 400,
		$line-height: 1.3,
	),
	$title: mat.define-typography-level(
		$font-size: 1.3rem,
		$font-weight: 500,
		$line-height: 1.3,
	),
	$subheading-2: mat.define-typography-level(
		$font-size: 1.05rem,
		$font-weight: 600,
		$line-height: 1.4,
	),
	$subheading-1: mat.define-typography-level(
		$font-size: 1.05rem,
		$font-weight: 500,
		$line-height: 1.5,
	),
	$body-1: mat.define-typography-level(
		$font-size: 1rem,
		$font-weight: 400,
		$line-height: 1.6,
	),
	$body-2: mat.define-typography-level(
		$font-size: 1rem,
		$font-weight: 500,
		$line-height: 1.6,
	),
	$caption: mat.define-typography-level(
		$font-size: 1rem,
		$font-weight: 500,
	),
);

@include mat.core($brandmagic-typography);

// primary and accent colours
$brandmagic-primary: mat.define-palette(mat.$deep-purple-palette, 400);
$brandmagic-accent: mat.define-palette(mat.$purple-palette, 400);

$brandmagic-theme: mat.define-light-theme((
	color: (
		primary: $brandmagic-primary,
		accent: $brandmagic-accent,
	)
));

@include mat.all-component-themes($brandmagic-theme);
@include mtx.all-component-themes($brandmagic-theme);

html, body {
	height: 100%;
}
body {
	margin: 0;
	font-family: 'Albert Sans', "Helvetica", sans-serif;
	overflow-y: hidden;
}

mat-icon.mat-icon {
	margin-right: 8px;
}

a {
	color: #660066;
}

a:visited {
	color: #660066;
}

a:hover {
	color: #990099;
}



// Fix for floating labels on HTML editor fields
.editor .mat-form-field-label,
.editor .mat-form-field-label,
{
	top: 0;
}


.editor.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .editor.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
.editor.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .editor.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
.editor.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.editor.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
{
	top: 20px;
}


.resources div.mat-tab-label {
	padding: 0;
	min-width: 40px;
	flex: 1 1 auto;
}

div.mtx-calendar-header-date-time  {
	font-size: 24px;
}

div.mat-form-field-flex .mat-form-field-infix {
	display: flex;
}

.quiz-result .mat-list-text {
	line-height: 1;
}
